export let available = [
  {
    name: "Yoga & Meditation Course",
    date: "Tuesday",
    slotsAvailable: 30,
    slotsFree: 16,
    booked: true,
    slotsBooked: 2,
    time: "10:30 - 11:45",
    location: "Deck A2",
    type: "event",
    icon: "yoga.svg",
    id: 0,
  },
  {
    name: "Beach Volleyball Training",
    date: "Tuesday",
    slotsAvailable: 16,
    slotsFree: 3,
    booked: true,
    slotsBooked: 1,
    time: "10:30 - 11:45",
    location: "Deck A4",
    type: "event",
    icon: "volleyball.svg",
    id: 1,
  },
  {
    name: "Barcelona City Tour",
    date: "Tuesday",
    slotsAvailable: 60,
    slotsFree: 9,
    booked: true,
    slotsBooked: 4,
    time: "13:30 - 17:00",
    location: "Gate B2",
    type: "event",
    icon: "tour.svg",
    id: 4,
  },
  {
    name: "Fitness Course",
    date: "Tuesday",
    slotsAvailable: 50,
    slotsFree: 29,
    booked: false,
    slotsBooked: 0,
    time: "09:30 - 10:30",
    location: "Room 411",
    type: "event",
    icon: "bicep.svg",
    id: 10,
  },
  {
    name: "Ship Expedition Tour",
    date: "Tuesday",
    slotsAvailable: 15,
    slotsFree: 8,
    booked: false,
    slotsBooked: 0,
    time: "10:00 - 11:30",
    location: "Room 05",
    type: "event",
    icon: "ship.svg",
    id: 11,
  },
  {
    name: "Drama Theater Course",
    date: "Tuesday",
    slotsAvailable: 12,
    slotsFree: 3,
    booked: false,
    slotsBooked: 0,
    time: "11:00 - 13:30",
    location: "Room 785",
    type: "event",
    icon: "theater.svg",
    id: 13,
  },
  {
    name: "Cinema: Titanic Movie",
    date: "Tuesday",
    slotsAvailable: 120,
    slotsFree: 40,
    booked: false,
    slotsBooked: 0,
    time: "20:00 - 22:30",
    location: "Room 785",
    type: "event",
    icon: "cinema.svg",
    id: 13,
  },
  {
    name: "Cinema: James Bond",
    date: "Tuesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "20:05 - 22:30",
    location: "Room 760",
    type: "event",
    icon: "cinema.svg",
    id: 13,
  },
  // Example Events
  {
    name: "Wed. Example Event #1",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 20,
  },
  {
    name: "Wed. Example Event #2",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 21,
  },
  {
    name: "Wed. Example Event #3",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 3,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 22,
  },
  {
    name: "Wed. Example Event #4",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 23,
  },
  {
    name: "Wed. Example Event #5",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 24,
  },
  {
    name: "Wed. Example Event #6",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 5,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 25,
  },
  {
    name: "Wed. Example Event #7",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 26,
  },
  {
    name: "Wed. Example Event #8",
    date: "Wednesday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 27,
  },
  {
    name: "Thu. Example Event #1",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 30,
  },
  {
    name: "Thu. Example Event #2",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 31,
  },
  {
    name: "Thu. Example Event #3",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 32,
  },
  {
    name: "Thu. Example Event #4",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 1,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 33,
  },
  {
    name: "Thu. Example Event #5",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 34,
  },
  {
    name: "Thu. Example Event #6",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 35,
  },
  {
    name: "Thu. Example Event #7",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 36,
  },
  {
    name: "Thu. Example Event #8",
    date: "Thursday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 37,
  },
  {
    name: "Fri. Example Event #1",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 40,
  },
  {
    name: "Fri. Example Event #2",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 3,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 41,
  },
  {
    name: "Fri. Example Event #3",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 2,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 42,
  },
  {
    name: "Fri. Example Event #4",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 43,
  },
  {
    name: "Fri. Example Event #5",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 44,
  },
  {
    name: "Fri. Example Event #6",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 45,
  },
  {
    name: "Fri. Example Event #7",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 46,
  },
  {
    name: "Fri. Example Event #8",
    date: "Friday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 1,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 47,
  },
  {
    name: "Sat. Example Event #1",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 50,
  },
  {
    name: "Sat. Example Event #2",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 51,
  },
  {
    name: "Sat. Example Event #3",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 4,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 52,
  },
  {
    name: "Sat. Example Event #4",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 53,
  },
  {
    name: "Sat. Example Event #5",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 54,
  },
  {
    name: "Sat. Example Event #6",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 55,
  },
  {
    name: "Sat. Example Event #7",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 2,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 56,
  },
  {
    name: "Sat. Example Event #8",
    date: "Saturday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 3,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 57,
  },
  {
    name: "Sun. Example Event #1",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 60,
  },
  {
    name: "Sun. Example Event #2",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 61,
  },
  {
    name: "Sun. Example Event #3",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 1,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 62,
  },
  {
    name: "Sun. Example Event #4",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 63,
  },
  {
    name: "Sun. Example Event #5",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 64,
  },
  {
    name: "Sun. Example Event #6",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 65,
  },
  {
    name: "Sun. Example Event #7",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 66,
  },
  {
    name: "Sun. Example Event #8",
    date: "Sunday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 67,
  },
  {
    name: "Mon. Example Event #1",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "07:00 - 08:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 70,
  },
  {
    name: "Mon. Example Event #2",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "08:00 - 09:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 71,
  },
  {
    name: "Mon. Example Event #3",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: false,
    slotsBooked: 0,
    time: "09:00 - 10:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 72,
  },
  {
    name: "Mon. Example Event #4",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 3,
    time: "14:00 - 15:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 73,
  },
  {
    name: "Mon. Example Event #5",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 2,
    time: "15:00 - 16:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 74,
  },
  {
    name: "Mon. Example Event #6",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 4,
    time: "18:00 - 19:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 75,
  },
  {
    name: "Mon. Example Event #7",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 5,
    time: "19:00 - 20:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 76,
  },
  {
    name: "Mon. Example Event #8",
    date: "Monday",
    slotsAvailable: 120,
    slotsFree: 17,
    booked: true,
    slotsBooked: 3,
    time: "20:00 - 22:00",
    location: "Room 999",
    type: "event",
    icon: "question-mark.svg",
    id: 77,
  },
];
